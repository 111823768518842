import _hexColorRegex from "hex-color-regex";
var exports = {};
var hexColorRegex = _hexColorRegex;

exports = function isHexcolor(hex) {
  return hexColorRegex({
    strict: true
  }).test(hex);
};

export default exports;